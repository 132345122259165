import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAjg5vGo9fmbuBKXMB-PrDBsaRbKlci1po",
  authDomain: "marketing-os-vbmc.firebaseapp.com",
  projectId: "marketing-os-vbmc",
  storageBucket: "marketing-os-vbmc.appspot.com",
  messagingSenderId: "433505598766",
  appId: "1:433505598766:web:3d1ed9aedb1e913be78ebf",
  measurementId: "G-5CKTPWXCPQ",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);

// Configura Remote Config para gerenciamento A/B
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
};
