import { db } from './firebase';
import { doc, updateDoc, increment } from 'firebase/firestore';

export const registerEvent = async (userId, eventType) => {
  try {
    // Atualiza o documento existente com o mesmo userId no Firestore
    const userTestDoc = doc(db, 'abTests', userId);
    await updateDoc(userTestDoc, {
      [eventType]: increment(1),
    });
    console.log(`Evento ${eventType} registrado para o usuário ${userId}`);
  } catch (error) {
    console.error('Erro ao registrar evento:', error);
  }
};
