import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { registerEvent } from '../metrics'; // Importe a função de rastreamento

const CallConfirmada = () => {
  useEffect(() => {
    const userId = localStorage.getItem('userId') || 'unknown_user'; // Identificador do usuário (idealmente substituir por auth)
    registerEvent(userId, 'confirmCall');
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#37bdc6] to-[#32a7b3] p-6">
      {/* Container Principal */}
      <div className="bg-white shadow-2xl rounded-lg p-10 md:p-14 max-w-3xl w-full text-center relative">
        {/* Ícone de Sucesso */}
        <div className="flex justify-center items-center w-20 h-20 rounded-full bg-[#37bdc6] text-white mx-auto mb-8 shadow-lg">
          <svg
            className="w-12 h-12"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2l4-4m6 2a9 9 0 11-18 0a9 9 0 0118 0z"
            ></path>
          </svg>
        </div>

        {/* Título Principal */}
        <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
          🎉 Parabéns! Reunião Confirmada!
        </h1>

        {/* Subtítulo */}
        <h2 className="text-lg md:text-2xl text-gray-600 mb-8">
          Fique atento às nossas comunicações por e-mail e WhatsApp. Nos vemos em breve!
        </h2>

        {/* Mensagem de Orientação */}
        <p className="text-base md:text-lg text-gray-500 mb-12">
          Se precisar ajustar o horário ou tiver alguma dúvida, não hesite em entrar em contato conosco.
        </p>

        {/* Botão de Retorno à Home */}
        <div className="mt-8">
        <Link
            to="https://api.whatsapp.com/send/?phone=5511939598454&text=Olá%21+Eu+gostaria+de+conversar+com+um+especialista.&type=phone_number&app_absent=0"
            className="px-8 md:px-12 py-3 md:py-4 bg-[#37bdc6] text-white font-semibold text-base md:text-lg rounded-full hover:bg-[#32a7b3] shadow-lg hover:shadow-xl transition-all duration-300"
          >
            Chamar no WhatsApp
          </Link>
        </div>

        {/* Rodapé */}
        <p className="mt-12 text-gray-400 text-sm">
          © {new Date().getFullYear()} VBMC Consultores. Todos os direitos reservados.
        </p>
      </div>
    </div>
  );
};

export default CallConfirmada;
