import React from 'react';
import { Link } from 'react-router-dom';

const NaoQuali = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#37bdc6] to-[#32a7b3] p-6">
      <div className="bg-white shadow-lg rounded-lg p-10 max-w-2xl w-full text-center relative">
        {/* Logo Encaixada no Topo */}
        <div className="absolute top-[-60px] left-1/2 transform -translate-x-1/2">
          <div className="w-28 h-28 rounded-full overflow-hidden shadow-xl border-4 border-white">
            <img
              src="https://vbmc.com.br/wp-content/uploads/2018/10/000-AVBMC-1.jpeg?id=1669"
              alt="VBMC Logo"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        
        {/* Texto Principal */}
        <h1 className="text-4xl font-bold text-gray-800 mt-20 mb-6">Agradecemos pelo seu interesse!</h1>
        <h2 className="text-xl text-gray-600 mb-8">
          Nós adoramos conhecer novos projetos e pessoas como você. Porém, no momento, sentimos que não estamos totalmente alinhados para uma parceria.
          <br /> Enviamos mais detalhes para o seu e-mail. 😉
        </h2>
        <p className="text-lg text-gray-500 mb-12">
          Enquanto isso, fique à vontade para explorar outros conteúdos em nosso site.
        </p>

        {/* Botão de Voltar para Home */}
        <div className="mt-12">
          <Link
            to="https://vbmc.com.br"
            className="mt-8 px-10 py-4 bg-[#37bdc6] text-white font-semibold text-lg rounded-full hover:bg-[#32a7b3] shadow-lg hover:shadow-2xl transition-all duration-300"
          >
            Voltar para a Home
          </Link>
        </div>
        
        {/* Rodapé */}
        <p className="mt-8 text-gray-400 text-sm">
          © {new Date().getFullYear()} VBMC Consultores. Todos os direitos reservados.
        </p>
      </div>
    </div>
  );
};

export default NaoQuali;
