import React from 'react';
import logo from '../../src/vbmc-40anos.png';

const Header = () => {
  return (
    <header className="fixed top-0 w-full bg-white py-4 flex justify-center items-center z-50 rounded bg-opacity-80 backdrop-blur-md">
      <a href='https://vbmc.com.br' target='_blank' rel="noreferrer"><img src={logo} alt="Logo" className="h-12" /></a>
    </header>
  );
};

export default Header;
