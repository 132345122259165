import React, { useEffect } from 'react';
import { registerEvent } from '../metrics'; // Importa a função de rastreamento

const Quali1 = () => {
  useEffect(() => {
    // Pega o mesmo userId do localStorage para garantir consistência
    const userId = localStorage.getItem('userId');
    if (userId) {
      registerEvent(userId, 'qualificado'); // Registra o evento "qualificado" para esse usuário
    } else {
      console.error('UserId não encontrado no localStorage!');
    }

    // Carrega o script do Calendly
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-[#37bdc6] to-[#32a7b3] p-4 md:p-6">
      {/* Container Principal */}
      <div className="bg-white shadow-2xl rounded-lg p-6 md:p-10 max-w-4xl w-full text-center relative">
        {/* Título */}
        <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-6">
          🎉 Parabéns! Você foi qualificado para uma <span className="text-[#37bdc6]">reunião diagnóstica!</span>
        </h1>
        
        {/* Subtítulo */}
        <h2 className="text-lg md:text-2xl text-gray-600 mb-8">
          Agende o melhor dia e horário para você e aproveite essa oportunidade de conversar com nossos especialistas.
        </h2>

        {/* Calendário Integrado do Calendly */}
        <div
          className="calendly-inline-widget border-2 border-dashed border-[#37bdc6] rounded-lg shadow-sm"
          data-url="https://calendly.com/reports-vbmc/reuniao-diagnostica?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=37bdc6"
          style={{ width: '100%', height: '650px' }}
        ></div>

        {/* Mensagem Complementar */}
        <p className="text-sm md:text-base text-gray-500 mt-6">
          Caso precise de ajuda para agendar sua reunião, entre em contato pelo WhatsApp: <a href="https://api.whatsapp.com/send/?phone=5511939598454&text=Olá%21+Eu+gostaria+de+conversar+com+um+especialista.&type=phone_number&app_absent=0" className="text-[#37bdc6] underline">Clique aqui</a>.
        </p>
      </div>
    </div>
  );
};

export default Quali1;
